@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%; /* 1rem = 16px */
}

body {
  font-size: 1rem; /* 16px */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  caret-color: transparent;
}
input,
textarea {
  caret-color: rgba(0, 0, 0, 0.87);
}
